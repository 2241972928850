<template>
    <div>
        <v-img
            :src="appLogo"
            class="py-1 mx-auto mb-11"
            contain
            max-width="256"
            position="center center"/>
    </div>
</template>

<script>
    import appLogo from "@/assets/_redesigne/title-logo.svg";

    export default {
        name: "brand-logo",
        data: () => ({
            appLogo
        })
    };
</script>

<style lang="scss">

</style>
