import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import { confirmed, email, max, min, required } from "vee-validate/dist/rules";

import appGoogleLogo from "@/assets/icons/ic-google-mini-logo.svg";

extend("min", {
    ...min,
    message: "{_field_} must have at least {length} symbols."
});

extend("max", {
    ...max,
    message: "{_field_} Password must have less than {length} symbols."
});

extend("confirmed", {
    ...confirmed,
    message: "{_field_} doesn't match with the new password."
});

extend("email", {
    ...email,
    message: "{_field_} is not a valid email address"
});

extend("required", {
    ...required,
    message: "{_field_} cannot be blank."
});

export default {
    data: () => ({
        appGoogleLogo
    }),
    components: {
        ValidationObserver,
        ValidationProvider
    }
};
