<template>
    <login-page class="login-page">
        <v-card
            class="mx-auto"
            flat
            max-width="304"
            min-width="304">
            <div class="logo-wrapper">
                <brand-logo/>
                <div class="separation-line"/>
            </div>
            <validation-observer v-slot="{ handleSubmit }">
                <v-form
                    class="auth-form"
                    @submit.prevent="handleSubmit(submitResetEmailForm)">
                    <slot name="header"/>

                    <div class="auth-form__header mb-6">
                        Enter your email and we’ll send you a link to reset your password.
                    </div>
                    <div
                        v-if="resetError"
                        class="error pa-2 mb-4 text--white">
                        {{ resetError }}
                    </div>

                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="Email"
                        rules="required|email"
                        tag="label">
                        <div class="font-weight-bold text-body-1 mb-2">
                            Email
                        </div>
                        <v-text-field
                            v-model="email"
                            hide-details="auto"
                            outlined
                            placeholder="example@example.com"
                            type="email"/>
                        <div class="text-body-1 text--error mt-1">
                            {{ errors[0] }}
                        </div>
                    </validation-provider>

                    <div class="d-flex justify-space-between align-center mt-6">
                        <span class="btn-auth-wrapper">
                            <v-btn
                                block
                                class="v-btn--auth font-weight-bold text-capitalize px-13"
                                color="#9FF4A9"
                                dark
                                depressed
                                height="34"
                                type="submit">
                                Send Email
                            </v-btn>
                        </span>
                    </div>
                </v-form>
            </validation-observer>
        </v-card>
    </login-page>
</template>

<script>
    import mixinAuth from "./mixin-auth.js";
    import LoginPage from "./login-page";
    import { recoverEmailRequest } from "@/api/authorizations.api";
    import BrandLogo from "./BrandLogo";

    export default {
        name: "forgot-password",
        components: {
            BrandLogo,
            LoginPage
        },
        mixins: [
            mixinAuth
        ],
        data: () => ({
            email: "",
            resetError: "",
            validationsTimeout: null
        }),
        methods: {
            async submitResetEmailForm () {
                const response = await recoverEmailRequest({ email: this.email });
                if (response.status === 404) {
                    this.resetError = response.data.message;
                } else {
                    this.$store.commit("showResetStatus", {
                        success: true,
                        message: "Check your email for further instructions"
                    });

                    await this.$router.push({ name: "login" });
                }
            }
        }
    };
</script>

<style lang="scss">
    .auth-form {
        &__header {
            font-size: 14px !important;
            line-height: 18px;
            text-align: center;
            max-width: 213px;
            margin: 0 auto;
        }
    }

    .login-page {
    .logo-wrapper {
        max-width: 112px;
        margin: 0 auto 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .separation-line {
            width: 50px;
            height: 1px;
            background-color: #464646;
        }
    }
}
</style>
