<template>
    <login-page>
        <v-card
            class="mx-auto"
            flat
            max-width="304"
            min-width="304">
            <div class="logo-wrapper">
                <brand-logo/>
                <div class="separation-line"/>
            </div>

            <validation-observer v-slot="{ handleSubmit }">
                <v-form
                    class="auth-form"
                    @submit.prevent="handleSubmit(submitResetPasswordForm)">
                    <slot name="header"/>
                    <div
                        v-if="resetError"
                        class="error pa-2 mb-4 text--white">
                        {{ resetError }}
                    </div>

                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="New Password"
                        rules="min:8|max:64|required"
                        tag="label"
                        vid="confirmation">
                        <div class="text-body-1 mb-2">
                            New Password
                        </div>
                        <v-text-field
                            v-model="password"
                            hide-details="auto"
                            outlined
                            placeholder="New Password"
                            type="password"/>
                        <div class="text-body-1 text--error mt-1">
                            {{ errors[0] }}
                        </div>
                    </validation-provider>

                    <validation-provider
                        v-slot="{ errors }"
                        mode="eager"
                        name="Password Confirm"
                        rules="confirmed:confirmation|required"
                        tag="label">
                        <div class="text-body-1 mt-4 mb-2">
                            Confirm New Password
                        </div>
                        <v-text-field
                            v-model="confirmPassword"
                            hide-details="auto"
                            outlined
                            placeholder="Password Confirm"
                            type="password"/>
                        <div class="text-body-1 text--error mt-1">
                            {{ errors[0] }}
                        </div>
                    </validation-provider>

                    <div class="d-flex justify-space-between align-center mt-4">
                        <span class="btn-auth-wrapper">
                            <v-btn
                                block
                                class="v-btn--auth font-weight-bold text-capitalize px-13"
                                color="#9FF4A9"
                                dark
                                depressed
                                height="34"
                                type="submit">
                                Send Email
                            </v-btn>
                        </span>
                    </div>
                </v-form>
            </validation-observer>
        </v-card>
    </login-page>
</template>

<script>
    import mixinAuth from "./mixin-auth.js";
    import LoginPage from "./login-page";
    import { recoverPasswordRequest } from "@/api/authorizations.api";
    import BrandLogo from "./BrandLogo";

    export default {
        name: "recover-password",
        components: {
            BrandLogo,
            LoginPage
        },
        mixins: [mixinAuth],
        data: () => ({
            password: "",
            confirmPassword: "",
            resetError: "",
            validationsTimeout: null
        }),
        methods: {
            async submitResetPasswordForm() {
                if (this.$route.query.token) {
                    const token = this.$route.query.token;
                    const response = await recoverPasswordRequest({
                        token,
                        password: this.password,
                        password_confirmation: this.confirmPassword
                    });

                    if (response.status === 404) {
                        this.resetError = response.data.message;
                    } else {
                        this.$emit("showResetStatus", {
                            success: true,
                            message:
                                "Reset password was successfully made. Now you can log in with new password"
                        });
                        await this.$router.push({ name: "login" });
                    }
                }
            }
        }
    };
</script>

<style scoped lang="scss">
.v-btn--auth {
    margin-top: 24px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
}

.logo-wrapper {
    max-width: 112px;
    margin: 0 auto 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .separation-line {
        width: 50px;
        height: 1px;
        background-color: #464646;
    }
}
</style>
