<template>
    <v-row
        align="center"
        class="login__row-card background"
        justify="center">
        <slot/>
    </v-row>
</template>

<script>
    import authBg from "@/assets/img/auth-bg.svg";

    export default {
        name: "login-page",
        data () {
            return {
                authBg
            };
        }
    };
</script>

<style lang="scss">
    $color-red: #ff1257;
    $color-orange: #ffef01;
    $color-grey: #464646;
    .login {
        .text--error {
            color: #ff5252;
        }
        .text--white {
            color: #fff;
        }
        &__row-card {
            height: calc(100vh - 64px);
        }
        .v-main__wrap {
            display: block;
        }
        & .auth-form {
            .v-input__slot {
                min-height: 34px;
            }
        }
    }
    .auth-form {
        .btn-auth-wrapper {
            display: inline-flex;
            position: relative;
            width: 100%;
            height: 100%;

            &::after {
            }

            .v-btn {
                z-index: 2;
            }
            &__header {
                font-size: 13px;
            }
        }
        .v-btn--auth {
            border: 1px solid $color-grey !important;
            border-radius: 50px !important;
            font-size: 16px;

            .v-btn__content {
                font-weight: 400;
            }

            &:not(.text--white) {
                color: #202121;
            }
        }


        .v-input__control {
            border-radius: 0 !important;
        }
        .v-text-field__slot {
            color: #222222;
            font-size: 13px;
        }
        label .text-body-1 {
            font-family: "Futura PT", sans-serif !important;
            line-height: 15px;


            &.text--error,
            &:first-child {
                font-size: 14px !important;
            }
            &:first-child {
                color: $color-grey;
            }
        }
    }

</style>
